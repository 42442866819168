import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from "../components/layout.js"
import EmailButton from "../components/EmailButton.js"
import ExperienceList from "../components/ExperienceList.js"
import TechList from "../components/TechList.js"

import config from "../utils/config"

export default () => (
    <Layout>
        <div className="intro">
            <div className="intro-body">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="brand-heading">{ config.siteTitle }</h1>
                            <p className="intro-text">{ config.siteDescription }</p>
                            <a href="#about" className="btn btn-circle js-scroll-trigger">
                                <FontAwesomeIcon icon="angle-double-down" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="about" className="content-section text-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <h2>&Agrave; propos de moi</h2>
                        { config.content.about.map ((sentence, stcIdx) => (<p key={stcIdx}>{sentence}</p>))}
                    </div>
                </div>
            </div>
        </section>
        <section id="technologies" className="content-section text-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <h2>Technologies</h2>
                        <TechList items={config.content.technologies} />
                    </div>
                </div>
            </div>
        </section>
        <section id="experiences" className="content-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <h2>Exp&eacute;riences</h2>
                        <ExperienceList items={config.content.experiences} />
                    </div>
                </div>
            </div>
        </section>
        <section id="training" className="content-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <h2>Formation</h2>
                        <dl>
                        {
                            config.content.training.map((trainingItem, idx) => (
                                <div key={"training-"+idx} className="row">
                                    <dt className="col-sm-3">
                                        {trainingItem.period}
                                    </dt>
                                    <dd className="col-sm-9">
                                        <p><strong>{trainingItem.title}</strong>, {trainingItem.location}</p>
                                        { trainingItem.hasOwnProperty("spec") ? <p className="small">Sp&eacute;cialit&eacute; : {trainingItem.spec}</p> : "" }
                                    </dd>
                                </div>
                            ))
                        }
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <h2>Langues</h2>
                        <dl>
                        {
                            config.content.languages.map((languageItem, idx) => (
                                <div key={"language-"+idx} className="row">
                                    <dt className="col-sm-3">
                                        {languageItem.name}
                                    </dt>
                                    <dd className="col-sm-9">
                                        <p>{languageItem.level}</p>
                                        { languageItem.hasOwnProperty("misc") ? <p className="small">{languageItem.misc}</p> : "" }
                                    </dd>
                                </div>
                            ))
                        }
                        </dl>
                    </div>
                </div>
            </div>
        </section>
        <section id="contact" className="content-section text-center">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-lg-10 mx-auto">
                        <h2 className="mb-2">CV</h2>
                        <a href="static/cv/CV-Romain_GONCALVES.pdf" className="btn btn-default btn-lg" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon="file-pdf" fixedWidth/>
                            <span className="network-name">T&eacute;l&eacute;charger mon CV simplifié</span>
                        </a>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-10 mx-auto">
                        <h2 className="mb-2">R&eacute;seaux Sociaux</h2>

                        <ul className="list-inline banner-social-buttons mt-0">
                            <li className="list-inline-item">
                                <a href={ config.content.social.twitter } target="_blank" rel="noopener noreferrer" className="btn btn-default btn-lg">
                                    <FontAwesomeIcon icon={["fab", "twitter"]} fixedWidth/>
                                    <span className="network-name">Twitter</span>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href={ config.content.social.github} target="_blank" rel="noopener noreferrer" className="btn btn-default btn-lg">
                                    <FontAwesomeIcon icon={["fab", "github"]} fixedWidth/>
                                    <span className="network-name">Github</span>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href={ config.content.social.linkedin} target="_blank" rel="noopener noreferrer" className="btn btn-default btn-lg">
                                    <FontAwesomeIcon icon={["fab", "linkedin"]} fixedWidth/>
                                    <span className="network-name">LinkedIn</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <h2 className="mb-2">E-Mail</h2>
                        <EmailButton />
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <div className="container text-center">
                <p className="lead">Copyright &copy; rgoncalves.fr {(new Date()).getFullYear() !== 2018 ? "2018 - "+(new Date()).getFullYear() : "2018"} - <a href="/legal">Mentions L&eacute;gales</a></p>
                <p className="font-weight-light">
                    <small>
                        Built with <a href="https://gatsbyjs.org/" target="_blank" rel="noopener noreferrer">Gatsby</a>, <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> and <a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap</a>.<br/>
                        Based on <a target="_blank" rel="noopener noreferrer" href="https://github.com/BlackrockDigital/startbootstrap-grayscale">Greyscale theme by Start Bootstrap</a>
                    </small>
                </p>
            </div>
        </footer>
    </Layout>
)
